import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import TextForm from './components/TextForm';
import Aboutus from './components/Aboutus';
import { useState } from 'react';
import Alert from './components/Alert';
import ReactDOM from "react-dom";


import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";


function App() {
  const [mode,setMode] = useState('light');
  const [alert,setAlert] = useState("");

  const showAlert = (message,type) => {  
    setAlert ({
      msg:message,
      type:type
    })
    setTimeout (()=>{
      setAlert(null);
    },1000)
  }
  const toggleMode = () => {
    if (mode==='light')  
    {
      setMode('dark');
      document.body.style.backgroundColor   = '#322234'
      showAlert("Dark","warning");
      
    }else
    {
      setMode('light')
      document.body.style.backgroundColor = 'white'
      showAlert("Light","success");
    }
  }
  return (
    <div className="container">
      <Navbar mode={mode} toggleMode={toggleMode} />
      <Alert alert={alert} mode={mode} />
      <BrowserRouter>
        <Routes>
          <Route path="/Aboutus" element={<Aboutus mode={mode} />} />

          <Route
            path="/"
            element={
              <TextForm heading="Enter the text to analyse: " mode={mode} />
            }
          />
       
        </Routes>
      </BrowserRouter>

      <div className="container my-3">
        
      </div>
    </div>
  );
}

export default App;

import React, { useState } from 'react'

function Aboutus(props) {

let myStyle = {
  color:props.mode === 'dark'?'white':'black',
  backgroundColor:props.mode === 'light'?'white':'black'
}

  return (
    <>
      <div className="container">
        <div className="accordion accordion-flush" style={{color:props.mode === 'dark'?'white':'black',}} id="accordionFlushExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                style={myStyle}
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                About the APP
              </button>
            </h2>
            <div
              id="flush-collapseOne" 
              className="accordion-collapse collapse"
              style={myStyle}
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                This app can be used to count the number which will be very helpful for students to write essays and other such uses.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                style={myStyle}
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                License
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              style={myStyle}
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
               This App is free to use
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                style={myStyle}
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                Developed by
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className="accordion-collapse collapse"
              style={myStyle}
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
           Developed for educational purpose by <strong >Sasitharani</strong>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </>
  );
}

export default Aboutus
import React from 'react'

function Alert(props) {

  const capitalise = (word) =>{
    console.log(word)
    const lower =word.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
  }
  return (
    <div style={{height:'100px'}}>
   {props.alert && <div>
  
  <div className={`alert alert-${props.alert.type} alert-dismissible fade show`} role="alert">
       <strong>{capitalise(props.alert.type)}</strong>: {props.alert.msg}
        {/* {capitalise('sasi')} */}
      </div>
    </div>}
    </div>
  );
}

export default Alert
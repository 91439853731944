import React, { useState } from 'react'

function TextForm(props) {
   
    const handleUpClick = () => {
        let newText=text.toUpperCase();
        setText(newText);
        props.showAlert("Converted to Uppsercase","success");
    }
    const handleLoClick = () => {
        let newText=text.toLowerCase();
        setText(newText);
        props.showAlert("Converted to Lowercase","success");
    }
    const handleClearText = () => {
        let newText='';
        setText(newText);
        props.showAlert("Text Cleared","success");
    }
    // const handleClearText = () => {
    //     let newText=text.;
    //     setText(newText);
    // }
    const changeText = (event) => {
        setText(event.target.value);
        
    }
    const [text,setText] = useState('');
  return (
    <>
    <div className='container' style={{color:props.mode==='light'?'black':'white'}}>
      <div className="mb-3">
        <label htmlFor="exampleFormControlTextarea1" className="form-label">
          {props.heading}
        </label>
        <textarea
        style={{backgroundColor:props.mode==='light'?'white':'black', color:props.mode==='light'?'black':'white' }}
        onChange={changeText}
          className="form-control"
          value={text}
          id="exampleFormControlTextarea1"
          rows="8"
        ></textarea>

        <button className="btn btn-primary mx-1 my-1" onClick={handleUpClick}>Change to Upper Case</button>
        <button className="btn btn-primary mx-1 my-1" onClick={handleLoClick}>Change to Lower Case</button>
        <button className="btn btn-primary mx-1 my-1" onClick={handleClearText}>Clear Text</button>

        <div className="container">
            <h6>Preview</h6>
            <p>{text}</p>

            <h6>No of words</h6>
            <p>{(text.split(' ').length)-1}</p>

            <h6>No Of Charecters</h6>
            <p>{text.length}</p>
        </div>
      </div>
    </div>


    </>
  );
}

export default TextForm;